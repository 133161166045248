import { AxiosWrapper } from './AxiosWrapper'

export const SortFilter = {
	mixins: [AxiosWrapper],
	data(){
		return {
			apiRequest: null,
			filterOptionRequest: null,
			recordsRaw    : [],
			filterVals    : {},
			searchText    : '',
			searchCriteria: [],
			searchResults : [],
			sort          : {
				column: 'id',
				asc: true,
			},
			totalCount    : 0,
			showPopover   : false,
		}
	},
	computed: {
		filterValue: {
			get() { return this.filterVals },
			set(v) { this.filterVals = v }
		},
		records(){
			let results = this.recordsRaw
			if (this.itemClassesFunction){
				results.forEach(item => {
					item._classes = this.itemClassesFunction(item)
				})
			}
			return results
		},
		hasSelectedFilter(){
			return Object.keys(this.filterValue).length > 0
		},
	},
	watch: {
		searchText(e){
			this.search(e)
		},
		filterValue: {
			deep: true,
			handler(){
				this.refresh()
			}
		},
		sort: {
			deep: true,
			handler(){
				if(this.page === 'flagged'){
					return this.feedback.sort(this.sortBy(this.sort.column,this.sort.asc))
				} else {
					this.refresh()
				}
			}
		},
		customDateRange: {
			deep: true,
			handler(){
				this.refresh()
			}
		}
	},
	methods: {
		search(e){
			switch (this.page){
				case 'feedback':
					this.searchResults = this.records.filter(x => (x.contact.name.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.contact.email.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.project.name.toLowerCase().indexOf(e.toLowerCase()) > -1))
					break
				case 'log':
					this.searchResults = this.records.filter(x => x.contact_name && (x.contact_name.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.to_email.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.project_name.toLowerCase().indexOf(e.toLowerCase()) > -1))
					break
				case 'queue':
					this.searchResults = this.records.filter(x => (x.project_name.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.contact_name.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.to_email.toLowerCase().indexOf(e.toLowerCase()) > -1))
					break
				case 'flagged':
					this.searchResults = this.records.map(({data,source})=>{
						let dataObject = JSON.parse(data)
						return {
							contact: dataObject.contact,
							email  : dataObject.email,
							rating : dataObject.rating,
							comment: dataObject.comment,
							project: dataObject.project,
							kudos  : dataObject.kudos ? dataObject.kudos : [],
							created: dataObject.created,
							source : source,
						}
					}).filter(x => (x.project.toLowerCase().indexOf(e.toLowerCase()) > -1)
									|| (x.contact.toLowerCase().indexOf(e.toLowerCase()) > -1)
									|| (x.email.toLowerCase().indexOf(e.toLowerCase()) > -1))
					break
				default:
					this.searchResults = this.records.filter(x => (x.contact.name.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.contact.email.toLowerCase().indexOf(e.toLowerCase()) > -1)
																	|| (x.project.name.toLowerCase().indexOf(e.toLowerCase()) > -1))
					break
			}
			if(e.length && !this.searchResults.length){
				this.searchResults = []
			}
		},
		changeSort(sort){
			this.sort = sort
		},
		sortBy(field,order){
			const key = (x) => x[field]
			let reverse = order ? 1 : -1
			return (a,b) => {
				a = key(a)
				b = key(b)
				return reverse * ((a > b) - (b > a))}
		},
		setFilter(value){
			if (typeof value === 'object' && value != null){
				Object.keys(value).forEach(key => {
					if (value[key] === null){
						delete value[key]
					}
				})
			}
			this.filterValue = value
			this.showPopover = false
		},
		clearFilter(){
			this.filterValue = Object.assign({}, this.defaultFilterValues)
		},
		removeFilter(key){
			const val = this.filterValue
			delete val[key]
			this.filterValue = Object.assign({}, val)
			// Changing the key causes the component to refresh which fixes an issue where clearing the value reverted the dropdown to the first option.
			this.filterSelectKey++
		},
		async loadRecords(){
			if (this.apiRequest){
				this.cancelAxiosCall()
			}
			if (!this.recordsEndpoint){
				window.showErrorToast('recordsEndpoint not defined')
			}
			try
			{
				let url = this.recordsEndpoint;
				url += (url.includes("?") ? '&' : '?')
				// Now we should do filtering on the server.
				if (this.filterValue){
					url += '&filters=' + encodeURIComponent(JSON.stringify(this.filterValue))
				}
				url += '&sort=' + encodeURIComponent(JSON.stringify(this.sort))
				if (this.addQueryParams){
					url = this.addQueryParams(url)
				}
				const response = await (this.apiRequest = this.get(url))
				// If user is logged out, response will be null. Just ignore it, because the page is going to redirect to login.
				if (response){
					this.recordsRaw = response.data.records
					this.totalCount = response.data.totalCount
					this.$emit('loaded-records', Object.assign({}, response.data, { records: this.recordsRaw }))
				}
				this.apiRequest = null // Do not nullify apiRequest if it was canceled due to another request being sent. (That's why I didn't use a finally block here.)
			}
			catch(e)
			{
				if (!e.__CANCEL__){
					console.error(e)
					if (!e.response || e.response.status !== 401){
						window.showErrorToast('An error occurred while loading records')
					}
					// Do not nullify apiRequest if it was canceled due to another request being sent.
					this.apiRequest = null
				}
			}
		},
		async refresh(){
			this.recordsRaw = [];
			await this.loadRecords();
			if (this.records)
				this.search(this.searchText)
		},
	},
	mounted(){
		this.refresh()
	}
}
