<template>
	<!-- <div class="mx-auto w-100">
		<div class="sk-circle">
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
			<div class="sk-circle-dot"></div>
		</div>
	</div> -->


	<!-- <div class="position-absolute" style="top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,0.4);">
		<CSpinner color="light" style="width:4rem;height:4rem;"/>
	</div> -->
	<div :class="wrapperClass">
		<CSpinner color="info" :style="style"/>
	</div>
</template>

<style src="spinkit/spinkit.min.css"></style>
<style>
	.spinner-sm {
		min-height: 22px;
		min-width: 22px;
	}
</style>

<script>
export default {
	name: 'spinner',
	props: ['size'],
	computed: {
		_size(){
			return this.size == 'sm' ? '1' : '4'
		},
		style() {
			return 'width:'+this._size+'rem;height:'+this._size+'rem;'
		},
		wrapperClass(){
			return 'spinner-wrapper ' + (this.size == 'sm' ? 'spinner-sm' : '')
		}
	}
}
</script>
