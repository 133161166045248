<template>
	<div>
		<router-view :key="$route.fullPath"></router-view>
		<div id="popup-forms"></div>
		<alert-modal ref="alertModal"></alert-modal>
		<confirm-modal ref="confirmModal"></confirm-modal>
        <DxConfirmPopup ref="dxConfirmPopup"/>
        <DxAlertPopup ref="dxAlertPopup"/>
		<Toast v-model="managedToasts"></Toast>
        <LeaveRequestModal v-if="isLoggedIn" ref="leaveRequestModal" />
        <ActionItemModal ref="actionItemModal" v-if="isLoggedIn && showActionItem" @hidden="showActionItem = false" />
	</div>
</template>

<script>
import AlertModal from './components/AlertModal'
import ConfirmModal from './components/ConfirmModal'
import DxConfirmPopup from './components/generic/DxConfirmPopup'
import DxAlertPopup from './components/generic/DxAlertPopup'
import Toast from './components/generic/Toast'
import ActionItemModal from './views/action-items/ActionItemModal'
import LeaveRequestModal from './views/leaves/LeaveRequest'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'

export default {
	name: 'App',
	props: ['toasts'],
	components: {AlertModal, ConfirmModal, Toast, ActionItemModal, LeaveRequestModal, DxConfirmPopup, DxAlertPopup},
    data(){
        return {
            showActionItem: false
        }
    },
	computed: {
		managedToasts: {
			get() { return this.toasts },
			set(v) { this.$emit('update-toasts', v) }
		},
		isLoggedIn(){
			return !!this.Current_User_ID
		},
	},
	methods: {
		showAlertModal(message, title){
			this.$refs.alertModal.showAlert(message, title);
		},
		showConfirmModal(options){
			this.$refs.confirmModal.showConfirmModal(options);
		},
        showConfirmModal2(options){
			this.$refs.dxConfirmPopup.show(options);
		},
        showAlertModal2(options){
			this.$refs.dxAlertPopup.show(options);
		},
        showActionItemModal(options){
            this.showActionItem = true
            this.$nextTick(()=>{ this.$refs.actionItemModal.show(options) })
		},
        showLeaveRequestModal(){
            this.$refs.leaveRequestModal.show()
        }
	},
}
</script>
<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
