import moment from 'moment'

const fixAcronyms = function(text){
	const acronyms = {
		'M S P': 'MSP',
		' D B': ' DB',
		'A T C': 'ATC',
		'C P U': 'CPU',
		'S L A': 'SLA',
		'U R L': 'URL',
		'K D G': 'KDG',
	}
	Object.keys(acronyms).forEach(k => {
		text = text.replace(k, acronyms[k]);
	})
	return text;
}
// reletiveTimeIntervals
const reletiveTimeIntervals = {
	minute: 0,
	hour: 1,
	day: 2,
}

export const TextFormatter = {
	methods: {
		formatLabel(text){
			if (typeof text !== 'string'){
				if (text && typeof text === 'object' && typeof(text.label) !== 'undefined'){
					return text.label
				}
				return text
			}

			// https://stackoverflow.com/a/7225450
			var result = text
				.replace( /(_\w)/g, function(k) { return k[1].toUpperCase();})
				.replace( /([A-Z])/g, " $1" )
				.replace( /(\/\w)/g, function(k) { return '/'+k[1].toUpperCase();})
			return fixAcronyms(result.charAt(0).toUpperCase() + result.slice(1))
		},
		formatUTCTimestamp(timestamp, format){
			format = format || 'M/D/YYYY hh:mm:ss a'
			return moment.utc(timestamp).local().format(format)
		},
		formatTimestamp(timestamp){
			let time = moment(timestamp)
			return time.format('M/D/YYYY hh:mm a');
		},
		formatDate(timestamp, format){
			format = format || 'MMM D, YYYY'
			if (timestamp){
				let date = moment(timestamp);
				if (date){
					return date.format(format)
				}
			}
			return ''
		},
		formatRelativeTime(timestamp, minIntervalType, longform, omitSuffix){
			if (!timestamp) {
				return ''
			}
			// Formatting possibilities:
			// 1m ago
			// 5h 30m ago (do not show minutes after 10 hours)
			// 3d ago
			// 4w 2d ago (do not show days after 10 weeks)
			minIntervalType = minIntervalType || 'minute'
			const minInterval = reletiveTimeIntervals[minIntervalType]
			let time = moment.utc(timestamp).local()
			let diff = moment().diff(time, 'minutes')
			let absDiff = Math.abs(diff)
			let result = ''
			const minutesNotation = (longform ? ' minutes ' : 'm ')
			const hoursNotation = (longform ? ' hours ' : 'h ')
			const daysNotation = (longform ? ' days ' : 'd ')
			const weeksNotation = (longform ? ' weeks ' : 'w ')
			if (absDiff < 60 && minInterval == reletiveTimeIntervals.minute){ // < hour
				result = absDiff + minutesNotation
			} else if (absDiff < 1440 && minInterval <= reletiveTimeIntervals.hour) { // < day
				result = Math.floor(absDiff / 60) + hoursNotation
				if (absDiff < 600 && minInterval == reletiveTimeIntervals.minute){ // < 10 hours
					result += ' ' + (absDiff % 60) + minutesNotation
				}
			} else if (absDiff < 10080 && minInterval <= reletiveTimeIntervals.day) { // < week
				result = Math.floor(absDiff / 1440) + daysNotation
			} else {
				result = Math.floor(absDiff / 10080) + weeksNotation
				if (absDiff < 100800 && minInterval <= reletiveTimeIntervals.day) { // < 10 weeks
					result += ((absDiff % 10080) / 1440).toFixed(0) + daysNotation
				}
			}
			return (minInterval == reletiveTimeIntervals.day && absDiff < 1440) ? 'today' : (result + (omitSuffix ? '' : (diff >= 0 ? 'ago' : 'from now')));
		},
		daysSince(timestamp){
			if (timestamp){
				let date = moment(timestamp);
				if (date){
					return Math.abs(date.diff(moment.now(), 'days'))
				}
			}
			return ''
		},
		daysFromNow(timestamp){
			if (timestamp){
				let date = moment(timestamp)
				if (date){
					return Math.abs(moment().diff(date, 'days'))
				}
			}
			return ''
		},
		formatCurrency(number){
			return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
		},
		formatPhoneNumber(number){
			var phone = number.replace(/[^\d]/g, "");
			var areaCode = phone.slice(0,3);
			var prefix = phone.slice(3,6);
			var line = phone.slice(6,10);
			var extension = phone.slice(10);
			var result = '';
			if(phone){
				result = '(' + areaCode + ')';
				if(prefix){
					result += ' ' + prefix;
				}
				if(line){
					result += ' - ' + line;
				}
				if(extension){
					result += ' ext. ' + extension;
				}
			}
			return result;
		},
		getMonth(date){
			date = moment(date)
			if (date){
				return date.format("M")
			}
		},
		isAre(number, singular, plural){
			return (number == 1 ? 'is ' : 'are ') + number + ' ' + (number == 1 ? singular : plural)
		},
		roundUpToHundredth(value){
			return Math.round((value*100).toFixed(1))/100
		},
		minutesToHours(value){
			return `${Math.floor(value/60).toString().padStart(2, '0')}:${Math.abs(value%60).toString().padStart(2, '0')}`
		}
	}
};
