var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex justify-content-start" }, [
    _c(
      "div",
      { staticStyle: { "margin-top": "3px" } },
      [_c("UserPhoto", { attrs: { src: _vm.imgSource } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mb-1" }, [
      _c("div", [_c("strong", [_vm._v(_vm._s(_vm.user.userName))])]),
      _vm._v(" "),
      _c("div", { staticStyle: { "font-size": "12px" } }, [
        _vm._v(_vm._s(_vm._f("formatUtcToLocalDateTime")(_vm.timestamp))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }