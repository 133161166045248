import Vue from 'vue'
import Router from 'vue-router'
import { IsTokenValid, GetDefaultPage } from '../_services/user.service'

// Containers
const TheContainer = () => import('../containers/TheContainer')

// Views
const LoginPage = () => import('../views/login/LoginPage')
const Dashboard = () => import('../views/dashboard/Dashboard')
// const SubscriptionForm = () => import('../views/subscriptions/SubscriptionForm')
// const SubscriptionsIndex = () => import('../views/subscriptions/SubscriptionsIndex')
// const ClientFormPage = () => import('../views/clients/ClientFormPage')
const ClientsIndex = () => import('../views/clients/ClientsIndex')
const ServicesIndex = () => import('../views/services/ServicesIndex')
const ResourcingPage = () => import('../views/resourcing/Resourcing')
const ResourceRequestPage = () => import('../views/resourcing/ResourceRequest')
//Settings - Start
const SettingsPage = () => import('../views/settings/SettingsPage')
const SettingsBoard = () => import ('../views/settings/SettingsBoard')
const GeneralSettings = () => import ('../views/settings/pages/GeneralSettings')
const DeviceTypeAddons = () => import ('../views/settings/pages/DeviceTypeAddons')
const InvoiceManagement = () => import ('../views/settings/pages/InvoiceManagement')
const ImportTools = () => import ('../components/ImportTools')
const SLAPlansList = () => import ('../views/sla-plans/SLAPlansList')
const ServiceTypesList = () => import ('../views/service-types/ServiceTypesList')
const ServiceWebhookLogs = () => import ('../views/projects-in-flight/ServiceWebhookLogs')
const TemplatesList = () => import ('../views/documents/TemplatesList')
const LevelsPage = () => import ('../views/settings/pages/LevelsPage')
const DepartmentsPage = () => import ('../views/settings/pages/DepartmentsPage')
const LeavePolicies = () => import('../views/settings/pages/LeavePolicies')
const KDGHolidays = () => import('../views/settings/pages/KDGHolidays')
const ScheduledTasks = () => import ('../views/settings/pages/ScheduledTasks')
const ProjectTypes = () => import ('../views/settings/pages/ProjectTypes')
const InFlightStatuses = () => import('../views/settings/pages/InFlightStatuses')
const ATCIcons = () => import('../views/settings/pages/ATCIcons')
const ActionItemSettings = () => import('../views/settings/pages/ActionItemSettings/ActionItemSettings')
const Roles = () => import('../views/settings/pages/Roles/Roles')
const UserPermissions = () => import('../views/settings/pages/UserPermissions/UserPermissions')
const LicenseImports = () => import('../views/license-billing/LicenseImports')
const KDGValuesSettings = () => import('../views/weekly-reflections/KDGValuesSettings')
const ClientCacheUI = () => import('../views/settings/pages/ClientCacheUI.vue')

//Settings - End
const DocumentForm = () => import('../views/documents/DocumentForm')
const DownloadPublicDocument = () => import('../views/documents/DownloadPublicDocument')
const ApprovalsPage = () => import('../views/pending-changes/ApprovalsPage')
const ClientPage = () => import('../views/clients/ClientPage')
const ServicePage = () => import('../views/services/ServicePage')
//start invoices
const InvoicesPage = () => import('../views/billing/InvoicesPage')
//end invoices
const FeedbackPage = () => import('../views/feedback/FeedbackPage')
//add new feedback page for individual coding
// const NewFeedbackPage = () => import('../views/feedback/NewFeedbackPage')
//add new kali feedback page for individual coding
const LandingPage = () => import('../views/kali-feedback/LandingPage')

const UsersPage = () => import('../views/users/UsersPage')
const RecurringInvoiceReport = () => import('../views/reports/RecurringInvoiceReport')
const SupportHoursReport = () => import('../views/reports/SupportHoursReport')
const GlobalEventTimeline = () => import('../views/timeline/GlobalEventTimeline')
const RevenueReport = () => import('../views/reports/RevenueReport')
const ResourcingReport = () => import('../views/resourcing/ResourcingReport')
// const NewFeedbackLandingPage = () => import('../views/feedback/NewFeedbackLandingPage')
const EmailsPage = () => import('../views/emails/EmailsPage')
const ProjectsInFlightPage = () => import('../views/projects-in-flight/ProjectsInFlightPage')
const ProjectDetailsSection = () => import('../views/projects-in-flight/ProjectDetailsSection')
const ErrorReport = () => import('../views/projects-in-flight/progress-update/ErrorReport')
const ReflectionForm = () => import('../views/weekly-reflections/ReflectionForm')
const ReflectionsReport = () => import('../views/weekly-reflections/ReflectionsReport')
const AnalyticsPage = () => import('../views/reports/AnalyticsPage')
const TimeReport = () => import('../views/reports/TimeReport')
const UserLeaves = () => import('../views/leaves/UserLeaves')
const AuthorizedUsersReport = () => import('../views/authorized-users/AuthorizedUsersReport')
const AgreementsReport = () => import('../views/agreements/AgreementsReport')
const LeavesReport = () => import('../views/reports/LeavesReport')
const EmailsReport = () => import('../views/reports/EmailsReport')
const AGMUninvoicedReport = () => import('../views/license-billing/AGMUninvoicedReport')
const LeaveBalanceReport = () => import('../views/leaves/LeaveBalanceReport')
const MetricsReport = () => import('../views/reports/MetricsReport')

// Partner page //
const partnerPage = () => import('../views/partner/partner')
const partnerDetails = () => import('../views/partner/partnerDetailsEmployee')
const partnerDetailsVendor = () => import('../views/partner/partnerDetailsVendor')
const partnerPayoutReport = () => import('../views/partner/partnerPayoutReport')
Vue.use(Router)


export const router = new Router({
	mode: 'hash', // https://router.vuejs.org/api/#mode
	activeClass: 'open active',
	scrollBehavior: () => ({ y: 0 }),
	routes: [
        {
            path:'/in-flight/:id',
            name: 'In Flight',
            component: ProjectDetailsSection,
        },
        {
            path: '/documents/:id/download',
            name: 'DownloadPublicDocument',
            component: DownloadPublicDocument
        },
		{
			path: '/',
			redirect: '/default',
			name: 'Home',
			component: TheContainer,
			children: [
				{
					path: '/dashboard',
					name: 'Dashboard',
					component: Dashboard,
				},
                {
					path: '/resourcing',
					name: 'Resourcing',
					component: ResourcingPage,
				},
                {
					path: '/resource-request',
					name: 'Resource Request',
					component: ResourceRequestPage,
				},
                {
                    path: '/leaves',
                    name: 'Leave Request',
                    component: UserLeaves
                },
				{
					path: '/approval',
					name: 'P9 Device Approvals',
					component: ApprovalsPage,
				},
				{
					path: '/emails',
					name: 'Emails',
					component: EmailsPage,
				},
				{
					path: 'in-flight',
					component: {
						render (c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'In Flight',
							component: ProjectsInFlightPage,
						},
						{
							path: ':id/error-report',
							name: 'Error Report',
							component: ErrorReport
						}
					]
				},
				{
					path: 'services',
					component: {
						render (c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'Services',
							component: ServicesIndex,
						},
						{
							path: ':id',
							name: 'View Service',
							component: ServicePage,
						},
					]
				},
				{
					path: 'clients',
					component: {
						render (c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'Clients',
							component: ClientsIndex,
						},
						{
							path: ':id',
							name: 'View Client',
							component: ClientPage,
						},
					]
				},
				{
					path: '/timeline',
					name: 'Timeline',
					component: GlobalEventTimeline,
				},
				// invoices start
				{
					path: '/invoices',
					name: 'Invoices',
					component: InvoicesPage,
				},
				// invoices end
				{
					path: '/feedback',
					name: 'Feedback',
					component: FeedbackPage,
				},
				// //adding new feedback page for individual slicing
				// {
				// 	path: 'newfeedback',
				// 	name: 'New Feedback',
				// 	component: NewFeedbackPage,
				// },
				// {
				// 	path:   'nfbas/:feedbackEmailee(.+)?/:rating([1-5])',
				// 	name:   'New Feedback Landing Page AS',
				// 	props:  true,
				// 	component: NewFeedbackLandingPage,
				// },
				//adding new feedback page for individual slicing
				{
					path: 'landingpage',
					name: 'Kali Landing Page',
					component: LandingPage,
				},
				{
					path: 'documents',
					component: {
						render (c) { return c('router-view') }
					},
					children: [
						{
							path: 'add',
							name: 'Add Document',
							component: DocumentForm,
						},
					]
				},
				{
					path: 'settings',
					name: 'Settings',
					component: SettingsPage,
				},
				{
					path: 'setting',
					name: 'Setting',
					component: SettingsBoard,
					children: [
						{
							path: 'general',
							name: 'General Settings',
							component: GeneralSettings,
						},
                        {
							path: 'general/project-types',
							name: 'Project Types',
							component: ProjectTypes,
						},
						{
							path: 'general/development',
							name: 'Development',
							component: ImportTools,
						},
                        {
							path: 'general/action-items',
							name: 'Action Item Settings',
							component: ActionItemSettings,
						},
						{
							path: 'general/kdg-values',
							name: 'Reflections KDG Values',
							component: KDGValuesSettings,
						},
						{
							path: 'general/client-cache',
							name: 'Client Data Cache',
							component: ClientCacheUI,
						},
						{
							path: 'user-management',
							name: 'Users Settings',
							component: UsersPage,
						},
                        {
							path: 'users/roles',
							name: 'Group Management',
							component: Roles,
						},
                        {
							path: 'users/roles/permissions',
							name: 'User Permissions',
							component: UserPermissions,
						},
						{
							path: 'users/levels',
							name: 'Levels',
							component: LevelsPage,
						},
						{
							path: 'users/departments',
							name: 'Departments',
							component: DepartmentsPage,
						},
						{
							path: 'users/leave-policies',
							name: 'Leave Polices',
							component: LeavePolicies,
						},
                        {
							path: 'users/kdg-holidays',
							name: 'KDG Holidays',
							component: KDGHolidays,
						},
						{
							path: 'services/sla-plans',
							name: 'Service Settings',
							component: SLAPlansList,
						},
						{
							path: 'services/types',
							name: 'Service Types',
							component: ServiceTypesList,
						},
						{
							path: 'services/device-addons',
							name: 'Device Type Addons',
							component: DeviceTypeAddons,
						},
						{
							path: 'documents/templates',
							name: 'Document Templates',
							component: TemplatesList,
						},
						{
							path: 'invoice-management',
							name: 'Invoice Management',
							component: InvoiceManagement,
						},
                        {
							path: 'license-billing',
							name: 'Invoice Management',
							component: LicenseImports,
						},
						{
							path: 'integrations/webhook-history',
							name: 'Service Webhooks',
							component: ServiceWebhookLogs,
						},
						{
							path: 'integrations/scheduled-tasks',
							name: 'Scheduled Tasks',
							component: ScheduledTasks,
						},
                        {
							path: 'in-flight/stage',
							name: 'In Flight Stages',
							component: InFlightStatuses,
						},
                        {
							path: 'in-flight/icons',
							name: 'ATC',
							component: ATCIcons,
						},
					],
				},
                {
                    path: '/weekly-reflection',
                    name: 'Weekly Reflection',
                    component: ReflectionForm
                },
				{
					path: '/reports',
					name: 'Reports',
					component: {
						render (c) { return c('router-view') }
					},
					children: [
                        {
							path: '',
							name: 'Analytics',
							component: AnalyticsPage,
						},
						{
							path: '/reports/recurring',
							name: 'Recurring',
							component: RecurringInvoiceReport,
						},
						{
							path: '/reports/supportHours',
							name: 'Support Hours',
							component: SupportHoursReport,
						},
                        {
							path: '/reports/reflections',
							name: 'Reflections Report',
							component: ReflectionsReport
						},
                        {
                            path: '/reports/revenue-report',
                            name: 'Revenue Report',
                            component: RevenueReport
                        },
                        {
                            path: '/reports/time-report',
                            name: 'Time Report',
                            component: TimeReport
                        },
                        {
                            path: '/reports/authorized-users',
                            name: 'Authorized Users',
                            component: AuthorizedUsersReport
                        },
                        {
                            path: '/reports/agreements',
                            name: 'Agreements Report',
                            component: AgreementsReport
                        },
                        {
                            path: '/reports/leaves',
                            name: 'Leaves Report',
                            component: LeavesReport
                        },
                        {
                            path: '/reports/emails',
                            name: 'Emails Report',
                            component: EmailsReport
                        },
                        {
                            path: '/reports/resourcing',
                            name: 'Resource Report',
                            component: ResourcingReport
                        },
						{
                            path: '/reports/uninvoiced-licenses',
                            name: 'AGM Uninvoiced Report',
                            component: AGMUninvoicedReport
                        },
                        {
                            path: '/reports/leave-balance',
                            name: 'Leave Balance Report',
                            component: LeaveBalanceReport
                        },
						{
							path: '/reports/metrics',
							name: 'Metrics Report',
							component: MetricsReport
						}
					]
				},
				{ path: '/login', component: LoginPage },
                { path: '/default', component: Dashboard},
				{
					path: '/partner',
					name: 'Partner',
					component: partnerPage,
				},
				{
					path: '/partnerdetails',
					name: 'PartnerDetails',
					component: partnerDetails,
				},
				{
					path: '/partnerdetailsvendor',
					name: 'PartnerDetailsVendor',
					component: partnerDetailsVendor,
				},
				{
					path: '/partnerpayoutreport',
					name: 'PartnerPayoutReport',
					component: partnerPayoutReport
				},

			],
		},

		{
			path: '/pages',
			redirect: '/pages/404',
			name: 'Pages',
			component: {
				render (c) { return c('router-view') }
			},
			children: [
				// {
				// 	path: '404',
				// 	name: 'Page404',
				// 	component: Page404
				// },
				// {
				// 	path: '500',
				// 	name: 'Page500',
				// 	component: Page500
				// },
				// {
				// 	path: 'login',
				// 	name: 'Login',
				// 	component: Login
				// },
				// {
				// 	path: 'register',
				// 	name: 'Register',
				// 	component: Register
				// }
			]
		},

	]
})

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
    if(!authRequired){
        next()
    }
    else if(!IsTokenValid()){
        let loginUrl = '/login'
        if(to.path != '/default'){
            loginUrl += to.path ? `?redirect_url=${to.fullPath}` : ''
        }
		return next(loginUrl);
    } else if(to.path == '/default'){
        return next(GetDefaultPage())
    }
    next()
})
