var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dx-field", class: _vm.cssClass }, [
    _vm.showLabel
      ? _c(
          "div",
          {
            ref: "dx-label",
            staticClass: "dx-field-label",
            class: _vm._labelClass,
          },
          [
            _vm.labelSlot ? _vm._t("label") : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "label-span", attrs: { id: "_" + _vm.labelId } },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.label) + "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.labelTooltip
              ? _c(
                  "DxTooltip",
                  {
                    attrs: {
                      "hide-on-outside-click": false,
                      target: "#_" + _vm.labelId,
                      "show-event": "mouseenter",
                      "hide-event": "mouseleave",
                      position: "right",
                      "max-width": "20em",
                      height: "auto",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.labelTooltip) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { ref: "dx-value", staticClass: "dx-field-value", class: _vm.valueClass },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }