import { HubConnectionBuilder, LogLevel,HttpTransportType } from "@microsoft/signalr";

export default {
    install(Vue) {
        // use a new Vue instance as the interface for Vue components to receive/send SignalR events
        // this way every component can listen to events or send new events using this.$questionHub
        const connectionHub = new Vue();
        Vue.prototype.$connectionHub = connectionHub;

        // Provide methods to connect/disconnect from the SignalR hub
        let connection = null;
        let startedPromise = null;
        let manuallyClosed = false;

        // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
        // Docs recommend listening onclose and handling it there.
        // This is the simplest of the strategies
        function start() {
            startedPromise = connection.start().catch((err) => {
                console.error("Failed to connect with hub", err);
                return new Promise((resolve, reject) =>
                    setTimeout(() => start().then(resolve).catch(reject), 5000)
                );
            });
            return startedPromise;
        }

        Vue.prototype.startSignalR = () => {
            if (!startedPromise){
                let token = ''
                try { token = JSON.parse(atob(JSON.parse(localStorage.user))).Token }
                catch { token = '' }

                connection = new HubConnectionBuilder()
                    .withUrl(`/connectionHub?access_token=${token}`,{
                        skipNegotiation: true,
                        transport: HttpTransportType.WebSockets
                    })
                    .configureLogging(LogLevel.Information)
                    .build();

                connection.on("ReceiveNotification", (notification) => {
                    connectionHub.$emit("receive-notification", JSON.parse(notification));
                });
                connection.on("ReceiveEmail", (email) => {
                    connectionHub.$emit("receive-email", email);
                });
                connection.on("ActionItemComment", (comment) => {
                    connectionHub.$emit("new-action-comment", JSON.parse(comment));
                })
                connection.on("InFlightUpdate", (update) => {
					if(typeof update == 'string'){
						update = JSON.parse(update)
					}
                    connectionHub.$emit("new-inflight-update", update);
                })
                connection.on("UpdateReflectionReport", () => {
                    connectionHub.$emit("reflection-report-updated");
                })
                connection.on("ResourcingUpdated", () => {
                    connectionHub.$emit("resourcing-updated");
                })
                connection.on("ReloadApprovalPanel", () => {
                    connectionHub.$emit("reload-approval-panel");
                })
                connection.onclose(() => {
                    if (!manuallyClosed) start();
                });

                start();
            }
        };

        Vue.prototype.stopSignalR = () => {
            if (!startedPromise) return;

            manuallyClosed = true;
            return startedPromise
                .then(() => connection.stop())
                .then(() => {
                startedPromise = null;
            });
        };
    },
};
